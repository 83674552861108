<template>
  <div>
    <div id="dms-page" class="flex-column body-2">
      <div class="flex-center width-1000">
        <h1 class="h1-title">iAppraise Interface for your DMS</h1>
        <div class="margin-top-2 flex-column">
          <div class="width-640 flex-column flex-center">
            <span> Export appraisals from iAppraise to your Dealership Management Solution in one click. </span>
            <span class="margin-top-05">
              Working with both systems has never been so easy. Currently supported solutions are:
            </span>
            <ul>
              <li>ERAnet</li>
              <li>Titan DMS</li>
              <li>Revolution DMS</li>
            </ul>
            <p>
              This short video tutorial will help you get started. It explains how to install and navigate iAppraise
              Interface using ERAnet as an example DMS.
            </p>
            <div class="horizontal-flex-center margin-top-2">
              <iframe
                src="https://www.youtube.com/embed/c93LqgRPDDs?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <h2 class="h2-title margin-top-2">Download iAppraise Interface</h2>
          <p>
            <span>Please note:</span> if you don't have .NET Framework 4.5 on your PC but your Windows user account has
            administrator rights, you can download the installer with dependencies.
          </p>
          <div class="margin-top flex-row">
            <div class="horizontal-flex-center">
              <h4 class="h4">System requirements:</h4>
              <ul>
                <li>Windows 7 or above</li>
                <li class="margin-top-025">.NET Framework 4.5</li>
              </ul>
            </div>
            <div class="horizontal-flex-center">
              <h4 class="h4">Download links:</h4>
              <div>
                <img class="margin-right-05" src="/assets/img/icon-download.svg" />
                <a href="/s/files/interfaces/iappraise_dms_setup_admin.exe">All users setup</a>
                <p class="margin-top-025">Requires admin rights</p>
              </div>
              <div class="margin-top">
                <img class="margin-right-05" src="/assets/img/icon-download.svg" />
                <a href="/s/files/interfaces/iappraise_dms_setup_admin.exe">All users setup with dependencies</a>
                <p class="margin-top-025">Requires admin rights, includes .NET Framework 4.5</p>
              </div>
              <div class="margin-top">
                <img class="margin-right-05" src="/assets/img/icon-download.svg" />
                <a href="/s/files/interfaces/iappraise_dms_setup_current_user.exe">Single user setup</a>
                <p class="margin-top-025">Doesn't require admin rights</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DMSInterface"
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#dms-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
